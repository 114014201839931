
import { Icon } from '@iconify/react';
import { Box, Button, Card, Container, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Label from '@nvapps/common/components/ui/Label';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import { useDebounce, useSessionStorage } from '@nvapps/common/hooks';
import { getCurrencySymbol } from '@nvapps/common/utils';
import { getFormatedDate } from '@nvapps/common/utils/dateUtils';
import { getPurchaseStatus, PaymentTypes } from '@nvtracker/common/helpers';
import { applyFilters } from '@nvtracker/common/utils';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DateTime from '../../components/Ui/DateTime';
import PurchaseDetailsDialog from '../../modals/PurchaseDetailsDialog';
import { usePlansById } from '../../store/plans';
import { usePurchases } from '../../store/purchases';
import { useProfileLanguage } from '../../store/user';

const getPlanById = (plan, plans) => plans && plans[plan] ? plans[plan].name : '-';

export default function Purchases({ detailsOpen }) {

    const { t } = useTranslation();
    const [purchases, isFetchingPurchases,, fetchPurchases] = usePurchases();
    const [plans, isFetchingPlans] = usePlansById();
    const language = useProfileLanguage();
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useSessionStorage('purchasesFilter', { text: '', sortModel: [{ field: 'creationDate', sort: 'desc' }] });
    const navigate = useNavigate();

    const getDevicesOfPurchase = useCallback(function getDevicesOfPurchase(purchase) {

        const devices = purchase.purchaseDevices || [];
        if (devices.length !== 1) return `${devices.length} ${t('devices')}`;
        else return devices[0].deviceName;
    }, [t]);

    const purchasesFiltered = useMemo(() =>
    {
        return applyFilters(purchases.map(e=> ({
            ...e,
            devices : getDevicesOfPurchase(e)
        })),
        [
            'id',
            { key : 'planId', formatter : (key, r) => getPlanById(r.planId, plans)},
            'devices',
            'total',
            { key : 'creationDate', formatter : (key, r) => getFormatedDate(r.creationDate, 'dd MMMM yyyy', language) },
            { key : 'statusDate', formatter : (key, r) => getFormatedDate(r.statusDate, 'dd MMMM yyyy', language) },
            { key : 'status', formatter : (key, r) => t(getPurchaseStatus(r.status).text) },
            { key : 'paymentType', formatter : (key, r) => t((PaymentTypes[r.paymentType] || PaymentTypes.Other).text) }
        ], filters.text);
    }, [purchases, filters.text, language, getDevicesOfPurchase, plans, t]);

    useDebounce(() => {
        setFilters(state => ({ ...state, text: searchQuery }));
    }, 200, [searchQuery])

    const columns =
    [{
        field: 'id',
        headerName: t('id'),
        width: 80,
        renderCell: ({ row : { id }}) =>
           <Typography variant="subtitle2" noWrap>
                {id}
            </Typography>,
    },
    {
        field: 'creationDate',
        headerName: t('creationDate'),
        flex : 0.2,
        minWidth : 150,
        renderCell: ({ row : { creationDate }}) =>  <DateTime datetime={creationDate} format='dd MMM yyyy' />,
    },
    {
        field: 'paymentType',
        headerName: t('payment'),
        width : 120,
        align : 'center',
        headerAlign : 'center',
        valueGetter: ({ row: { paymentType } }) => t((PaymentTypes[paymentType] || PaymentTypes.Other).text),
        renderCell: ({ row : { paymentType }}) => <Label color='info'>{t((PaymentTypes[paymentType] || PaymentTypes.Other).text)}</Label>
    },
    {
        field: 'status',
        headerName: t('status'),
        width : 120,
        align : 'center',
        headerAlign : 'center',
        valueGetter: ({ row: { status } }) => t(getPurchaseStatus(status).text),
        renderCell: ({ row : { status }}) => <Label color={getPurchaseStatus(status).color}>{t(getPurchaseStatus(status).text)}</Label>
    },
    {
        field: 'statusDate',
        headerName: t('statusDate'),
        flex : 0.2,
        minWidth : 150,
        renderCell: ({ row : { statusDate }}) => <DateTime datetime={statusDate} format='dd MMM yyyy' />
    },
    {
        field: 'devices',
        headerName: t('device_s'),
        flex : 0.2,
        minWidth : 150,
        renderCell: ({ row }) => row.devices,
    },
    {
        field: 'planId',
        headerName: t('plan'),
        flex : 0.2,
        minWidth : 150,
        valueGetter: ({ row: { planId } }) => getPlanById(planId, plans)
    },
    {
        field: 'total',
        headerName: t('total'),
        minWidth : 100,
        align : 'right',
        headerAlign : 'right',
        renderCell: ({ row : { total, currency }}) =>  `${total.toLocaleString()} ${getCurrencySymbol(currency)}`,
    },
    {
        field: 'options',
        sortable: false,
        renderHeader: _ => <div></div>,
        renderCell: ({ row }) => <Tooltip title={t('seeDetails')} placement="top">
            <IconButton>
                <Icon icon="eva:search-outline" width={20} height={20} />
            </IconButton>
        </Tooltip>,
        align: 'right',
        width: 70
    }];

    return <React.Fragment>
        <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h4" >
                    <Trans>purchases</Trans>
                </Typography>
            </Stack>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TableToolbar>
                    <SearchBox
                        value={searchQuery}
                        onChange={ev => setSearchQuery(ev.target.value)}
                        placeholder={`${t('search')}...`} />
                    <Button
                        sx={{ ml: 'auto' }}
                        variant="outlined"
                        onClick={() => fetchPurchases(true)}
                        startIcon={<Icon icon="eva:refresh-fill" />}>
                        <Trans>refresh</Trans>
                    </Button>
                </TableToolbar>
                <Box sx={{ flex: 1, width: '100%' }}>
                    <DataGrid
                        columns={columns}
                        rows={purchasesFiltered}
                        loading={isFetchingPurchases || isFetchingPlans}
                        density="comfortable"
                        pagination
                        getRowId={d => d.id}
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        onRowClick={({ row }) => navigate(`/app/purchases/${row.id}`)}
                        sortModel={filters.sortModel}
                        onSortModelChange={sortModel => setFilters({ ...filters, sortModel })}
                        pageSize={10} />
                </Box>
            </Card>
        </Container>
        <PurchaseDetailsDialog open={Boolean(detailsOpen)} onClose={() => navigate('/app/purchases/list')} />
    </React.Fragment>
}