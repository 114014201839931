import { useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useLayoutEffect, useState } from "react"
import { getAuthenticatedImage } from "./utils";

function getWindowHeight() {
    return (
        (document &&
            document.documentElement &&
            document.documentElement.clientHeight) ||
        window.innerHeight
    );
}

export function useRealVerticalHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
        const updateHeight = () => setHeight(getWindowHeight());
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        }
    }, []);
    return height;
}

// export const useFetch = (fetchFn) => {
//     const [data, setData] = React.useState(null);
//     const [isFetching, setIsFetching] = React.useState(true);
//     const [error, setError] = React.useState(null);

//     React.useEffect(() => {
//         setIsFetching(true);
//         fetchFn().then(res => {
//             if (res.status === 200)
//                 setData(res.data);
//             else
//                 setError(res.data);
//             setIsFetching(false);
//         });
//     }, []);

//     return [data, isFetching, error];
// }

export function useFetch(fetchFn, deps) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const reFetch = () => {

        if (!fetchFn) return;

        setIsLoading(true);
        fetchFn().then(res => {
            if (res.status === 200 || res.status === 201)
                setData(res.data);
            else if (res.status === 204)
                setData(null);
            else
                setError(res.data);
            setIsLoading(false);
        }).catch(res => {
            setIsLoading(false);
        });
    }

    useEffect(() => reFetch(), deps);
    return { data, isLoading, error, reFetch };
}


export function useFetchV2(fetchFn, deps) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    let isSubscribed = true;

    const reFetch = () => {

        if (!fetchFn) return;

        setIsLoading(true);
        setError(null);

        fetchFn().then(res => {

            if (isSubscribed === true)
            {
                if (res.status === 200 || res.status === 201) setData(res.data);
                else if (res.status === 204) setData(null);
                else setError(res.data);
            }

        }).catch(res => {

            if (isSubscribed === true) setError(res);
        }).finally(res => {
            if (isSubscribed === true) setIsLoading(false);
        });
    }

    useEffect(() =>
    {
        reFetch();
        return () => (isSubscribed = false);

    }, deps);
    return [data, isLoading, error, reFetch];
}

export function useIsMobile(breakpoint = 'md') {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(breakpoint));
}

export function useTimeout(callback, delay) {
    const callbackRef = useRef(callback)
    const timeoutRef = useRef()

    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    const set = useCallback(() => {
        timeoutRef.current = setTimeout(() => callbackRef.current(), delay)
    }, [delay])

    const clear = useCallback(() => {
        timeoutRef.current && clearTimeout(timeoutRef.current)
    }, [])

    useEffect(() => {
        set()
        return clear
    }, [delay, set, clear])

    const reset = useCallback(() => {
        clear()
        set()
    }, [clear, set])

    return { reset, clear }
}

export function useDebounce(callback, delay, dependencies) {
    const { reset, clear } = useTimeout(callback, delay)
    useEffect(reset, [...dependencies, reset])
    useEffect(clear, [])
}

export function useInterval(callback, delay) {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes.
    useLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        if (!delay) return
        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}

export function useClock(refreshTime = 1000) {
    const [date, setDate] = useState(new Date());
    useInterval(() => setDate(new Date()), refreshTime);
    return date;
}

export function useLocalStorage(key, defaultValue) {
    return useStorage(key, defaultValue, window.localStorage)
}

export function useSessionStorage(key, defaultValue) {
    return useStorage(key, defaultValue, window.sessionStorage)
}

function useStorage(key, defaultValue, storageObject) {
    const [value, setValue] = useState(() => {
        const jsonValue = storageObject.getItem(key)
        if (jsonValue != null) return JSON.parse(jsonValue)

        if (typeof defaultValue === "function") {
            return defaultValue()
        } else {
            return defaultValue
        }
    })

    useEffect(() => {
        if (value === undefined) return storageObject.removeItem(key)
        storageObject.setItem(key, JSON.stringify(value))
    }, [key, value, storageObject])

    const remove = useCallback(() => {
        setValue(undefined)
    }, [])

    return [value, setValue, remove]
}

export default function useDialogOpener(initialProps = {}) {
    const [open, setOpen] = useState(false);
    const [dialogProps, setDialogProps] = useState(initialProps);

    const handleOpenDialog = value => {
        setOpen(true);
        setDialogProps(value);
    }

    const handleCloseDialog = value => {

        setOpen(false);
        setDialogProps({ ...initialProps, ...(value || {}) });
    }

    return { open, props: dialogProps, handleOpenDialog, handleCloseDialog };
}

export function useAuthenticatedImg(src, token) {
    const [fetching, setFetching] = useState(false)
    const [image, setImage] = useState('');

    useEffect(() => {

        if (token) {
            setFetching(true);
            getAuthenticatedImage(src, token)
                .then(res => {
                    setImage(res || '');
                    setFetching(false);
                })
                .catch(res => {

                    setImage('');
                    setFetching(false);
                });
        }
        else {
            setImage(src);
        }
    }, [src]);

    return { image, fetching };
}