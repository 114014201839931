import { Icon } from '@iconify/react';
import { Box, Card, CardContent, Grid } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import InputPassword from '@nvapps/common/components/ui/InputPassword';
import { validatePassword } from '@nvapps/common/utils';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSaveApi } from '../../hooks';
import { useUserActions } from '../../store/user';

function getErrors(form) {
    let err = [];
    if (!form) return null;

    const oldPass = (form.oldPassword || '').trim();
    const newPass = (form.newPassword || '').trim();
    const confirmPass = (form.confirmPassword || '').trim();

    var oldPassValid = oldPass.length > 0;
    var newPassValid = validatePassword(newPass);

    if (!oldPassValid) err.push({ id: "oldPassword", message: "requiredField" });
    else if (!newPassValid) err.push({ id: "newPassword", message: "newPasswordMustBe6Chars" });
    else if (oldPass === newPass) err.push({ id: "newPassword", message: "newPasswordMustBeDifferentThanOldPassword" });
    else if (newPass !== confirmPass) err.push({ id: "confirmPassword", message: "passwordsMustMatch" });

    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Form({ form, errors, onChange }) {

    const { t } = useTranslation();

    const setField = (name, value) => {
        onChange && onChange({ ...form, [name]: value });
    }

    function getMessage(key)
    {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : '';
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <InputPassword
                label={t('oldPassword')}
                fullWidth
                value={form.oldPassword}
                error={hasError(errors, "oldPassword")}
                helperText={getMessage("oldPassword")}
                onChange={e => setField("oldPassword", e.target.value)}
                />
        </Grid>
        <Grid item xs={12}>
            <InputPassword
                label={t('newPassword')}
                fullWidth
                value={form.newPassword}
                error={hasError(errors, "newPassword")}
                helperText={getMessage("newPassword")}
                onChange={e => setField("newPassword", e.target.value)} />
        </Grid>
        <Grid item xs={12}>
             <InputPassword
                label={t('confirmPassword')}
                fullWidth
                value={form.confirmPassword}
                error={hasError(errors, "confirmPassword")}
                helperText={getMessage("confirmPassword")}
                onChange={e => setField("confirmPassword", e.target.value)} />
        </Grid>
    </React.Fragment>
}

const changePasswordInitialProps = { oldPassword : '', newPassword : '', confirmPassword : '' };

export default function ProfileChangePassword() {

    const [form, setForm] = useState(changePasswordInitialProps);
    const { t } = useTranslation();
    const errors = useMemo(() => getErrors(form), [form]);
    const hasErrors = errors !== null;
    const [saveApi, isSaving] = useSaveApi({ errorText : t('errorOldPass') });
    const { changePassword } = useUserActions();

    const handleSave = async () => {
        const res = await saveApi(changePassword(form));
        if (res !== false) setForm(changePasswordInitialProps);
    }
    const handleChange = form => setForm(form);

    return (
        <Card >
            <CardContent >
                <Grid container spacing={3}>
                    <Form
                        form={form}
                        errors={errors}
                        onChange={handleChange} />
                    <Grid item xs={12} >
                        <Box display="flex" justifyContent="flex-end">
                            <ButtonWithProgress
                                onClick={handleSave}
                                loading={isSaving}
                                disabled={isSaving || hasErrors}
                                variant="contained"
                                size="small"
                                startIcon={<Icon icon="ant-design:save-filled" />}>
                                <Trans>
                                    save
                                </Trans>
                            </ButtonWithProgress>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
