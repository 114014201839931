import axios from "axios";
import { getJwtTokenCookie, getAuthenticatedImage as getAuthenticatedImageUtils } from "@nvapps/common/utils";

export const axiosInstance = axios.create();

export function authRequest({ headers,...opts }) {
    const token = getJwtTokenCookie();
    return axiosInstance({
        method: "GET",
        headers: { Authorization: `Bearer ${token}`, ...headers},
        ...opts
    });
}

export async function getAuthenticatedImage(url)
{
    return getAuthenticatedImageUtils(url, getJwtTokenCookie());
}

export const usersApiBaseUrl = "https://users.nvapps.novatronica.com";
export const usersPagesBaseUrl = "https://users.nvapps.novatronica.com";

// export const usersApiBaseUrl = "https://localhost:44373";
// export const usersPagesBaseUrl = "http://localhost:3002";
