
import { Icon } from '@iconify/react';
import { Box, Button, Card, Container, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Label from '@nvapps/common/components/ui/Label';
import { PhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import SmsInfo from '@nvapps/common/components/ui/SmsInfo';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import { useDebounce, useSessionStorage } from '@nvapps/common/hooks';
import { getFormatedDate } from '@nvapps/common/utils/dateUtils';
import { isDeviceActive } from '@nvtracker/common/devices';
import { getStatusTextOfContract } from '@nvtracker/common/helpers';
import { applyFilters } from '@nvtracker/common/utils';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ContractCell, { getDeviceContractStatusText } from '../../components/Ui/ContractCell';
import DateTime from '../../components/Ui/DateTime';
import { useDevices } from '../../store/devices';
import { usePlansById } from '../../store/plans';
import { useProfileLanguage } from '../../store/user';

const getPlanById = (plan, plans) => plans && plans[plan] ? plans[plan].name : '-';

export default function Devices() {

    const { t } = useTranslation();
    const [devices, isFetchingDevices,, fetchDevices] = useDevices();
    const [plans, isFetchingPlans] = usePlansById();
    const language = useProfileLanguage();
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useSessionStorage('devicesFilter', { text: '', sortModel: [{ field: 'name', sort: 'asc' }] });
    const navigate = useNavigate();

    const devicesFiltered = useMemo(() => applyFilters(devices, [
        'name',
        'brand',
        'model',
        'serialNumber',
        'gsmNumber',
        { key: 'renewalDate', formatter: (key, r) => getFormatedDate(r.renewalDate, 'PPP', language) },
        { key: 'planId', formatter: (key, r) => getPlanById(r.planId, plans) },
        { key: 'status', formatter: (key, r) => t(getStatusTextOfContract(r.renewalDate)) }
    ], filters.text), [devices, filters.text, t, language, plans]);

    useDebounce(() => {
        setFilters(state => ({ ...state, text: searchQuery }));
    }, 200, [searchQuery])

    const columns = [
        {
            field: 'name',
            headerName: t('name'),
            flex: 0.3,
            minWidth: 150,
            renderCell: ({ row: { name, brand, model } }) =>
                <Box style={{ lineHeight: 1.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {`${name}`}
                    </Typography>
                    <Typography variant="caption" noWrap >
                        {[brand, model].filter(e => e).join(' ')}
                    </Typography>
                </Box>
        },
        {
            field: 'serialNumber',
            headerName: t('info'),
            width: 200,
            renderCell: ({ row: { serialNumber, gsmNumber } }) =>
                <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2" noWrap>
                        {serialNumber}
                    </Typography>
                    <Typography variant="caption" noWrap pt={0.5}>
                        <PhoneNumber value={gsmNumber} />
                    </Typography>
                </Box>
        },
        {
            field: 'smsCredits',
            headerName: t('smsBalance'),
            width : 130,
            align : 'center',
            headerAlign : 'center',
            renderCell: ({ row: { smsCredits, smsLimit, smsDate } }) => <SmsInfo credits={smsCredits} limit={smsLimit} date={smsDate} placement="top" tooltip={<div>
            <Trans>lastUpdate</Trans>
                <br/>
                <DateTime datetime={smsDate} format="PPPpp" />
            </div>} />
        },
        {
            field: 'planId',
            headerName: t('plan'),
            minWidth: 150,
            flex: 0.2,
            valueGetter: ({ row: { planId } }) => getPlanById(planId, plans),
        },
        {
            field: 'renewalDate',
            headerName: t('renewalDate'),
            width: 175,
            renderCell: ({ row: { renewalDate } }) => <DateTime datetime={renewalDate} format="PPP" />
        },
        {
            field: 'status',
            valueGetter: ({ row: { renewalDate } }) => renewalDate ? t(getDeviceContractStatusText(renewalDate), { date: new Date(renewalDate) }) : '-',
            headerName: t('status'),
            width: 200,
            renderCell: ({ row }) => isDeviceActive(row) ? <ContractCell renewalDate={row.renewalDate} /> : <Label color='error' >
                <Typography variant="subtitle2" noWrap>{t('disabled')}</Typography>
            </Label>
        },
        {
            field: 'options',
            sortable: false,
            renderHeader: _ => <div></div>,
            renderCell: ({ row }) => <Tooltip title={t('seeDetails')} placement="top">
                <IconButton>
                    <Icon icon="eva:search-outline" width={20} height={20} />
                </IconButton>
            </Tooltip>,
            align: 'right',
            width: 70
        }];

    return (
        <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h4" >
                    <Trans>devices</Trans>
                </Typography>
            </Stack>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TableToolbar>
                    <SearchBox
                        value={searchQuery}
                        onChange={ev => setSearchQuery(ev.target.value)}
                        placeholder={`${t('search')}...`} />
                    <Button
                        sx={{ ml: 'auto' }}
                        variant="outlined"
                        onClick={() => fetchDevices(true)}
                        startIcon={<Icon icon="eva:refresh-fill" />}>
                        <Trans>refresh</Trans>
                    </Button>
                </TableToolbar>
                <Box sx={{ flex: 1, width: '100%' }}>
                    <DataGrid
                        columns={columns}
                        rows={devicesFiltered}
                        loading={isFetchingDevices || isFetchingPlans}
                        density="comfortable"
                        pagination
                        getRowId={d => d.id}
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        onRowClick={({ row }) => navigate(`/app/devices/${row.id}`)}
                        sortModel={filters.sortModel}
                        onSortModelChange={sortModel => setFilters({ ...filters, sortModel })}
                        pageSize={10} />
                </Box>
            </Card>
        </Container>

    )
}